import styled from "styled-components";

const ProductionPage = styled.section`
    background-image: url(http://localhost:3000/Image/back.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: grid;
    place-content: start;
`;

const Product = styled.h1`
    font-size: 32px;
    font-weight: bold;
    position: absolute;
    top: 25%;
    left: 28%;
`;

const OverLay = styled.div`
    height: 50vh;
    width: 100%;
    position: relative;
    top: 80%;
    left: 25%;
    transition: translate(80%, 25%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
`;

const ProductRedirector = styled.div`
    height: 100px;
    width: 100px;
    background-color: #000;
    cursor: pointer;
    border-radius: 15px;
`;

const OverlayHeader = styled.h3``;

export default function ManiProduct() {
    return (
        <ProductionPage>
            <Product>Our Product</Product>
            <OverLay>
                <ProductRedirector onClick={ () => window.location.href = "" }></ProductRedirector>
                <ProductRedirector onClick={ () => window.location.href = "" }></ProductRedirector>
                <ProductRedirector onClick={ () => window.location.href = "" }></ProductRedirector>
                <ProductRedirector onClick={ () => window.location.href = "" }></ProductRedirector>
                <ProductRedirector onClick={ () => window.location.href = "" }></ProductRedirector>
                <OverlayHeader></OverlayHeader>
            </OverLay>
       </ProductionPage>
    );
}