import styled from "styled-components";
import { AiFillProject } from "react-icons/ai";
const ProjectIcon = styled(AiFillProject)``;
const ProjectContainer = styled.section`width: 100%;display: flex;flex-direction: column;align-items: center;position: relative;top: 150px;margin-bottom: 200px;`;
const Container = styled.div`width: 80%;margin-top: 20px;display: flex;flex-wrap: wrap;flex-direction: column;justify-content: center;align-items: center;gap: 25px;`;
const ContainerHeader = styled.h1`font-size: 32px;font-style: normal;margin: 0;padding: 25px 0;z-index: 1:font-weight: bold;display: flex;flex-direction: row;justify-content: center;align-items: center;gap: 5px;transition: all 0.4s ease;&:hover {transform: scale(1.05);}`;
const ProjectDivision = styled.div`height: auto;width: 90%;display: flex;flex-direction: column;justify-content: center;align-items: center;box-shadow: 0px 0px 4px #0000006b;padding: 0;`;
const ProjectVideo = styled.video``;
const ProjectSrc = styled.source``;
const Heading = styled.h1`color: #000;font-size: 55px;text-shadow: 2px 0px #000000;`;
const Description = styled.p`text-align: justify;font-size: 18px;margin: 0px 10px 10px 10px;`;
const Overview = styled.button`font-size: 22px;font-weight: bold;background: #000;color: #fff;padding: 9px 18px;cursor: pointer;border: none;outline: 1px solid #000;border-radius: 5px;margin-bottom: 20px;transition: all 0.4s ease;&:hover{background: #fff;color: #000;}`;

export default function Project() {
    return (
        <ProjectContainer>
            <Container>
                <ContainerHeader>
                    Project<ProjectIcon />
                </ContainerHeader>

                {/* Project Overvicew */}

                <ProjectDivision>
                    <ProjectVideo>
                        <ProjectSrc></ProjectSrc>
                    </ProjectVideo>
                    <Heading>Project Heading</Heading>
                    <Description>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo nemo, molestiae quasi sapiente quam facere magni exercitationem enim et perferendis, saepe quibusdam voluptate esse dolores repellat minima, fugiat deserunt itaque fugit animi eum veniam! Cupiditate ut, quis, consectetur quos porro nam accusantium consequatur aliquid ratione, pariatur voluptate facere libero numquam!</Description>
                    <Overview>Learn More.,</Overview>
                </ProjectDivision>

                <ProjectDivision>
                    <ProjectVideo>
                        <ProjectSrc></ProjectSrc>
                    </ProjectVideo>
                    <Heading>Project Heading</Heading>
                    <Description>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo nemo, molestiae quasi sapiente quam facere magni exercitationem enim et perferendis, saepe quibusdam voluptate esse dolores repellat minima, fugiat deserunt itaque fugit animi eum veniam! Cupiditate ut, quis, consectetur quos porro nam accusantium consequatur aliquid ratione, pariatur voluptate facere libero numquam!</Description>
                    <Overview>Learn More.,</Overview>
                </ProjectDivision>

                <ProjectDivision><ProjectVideo><ProjectSrc></ProjectSrc></ProjectVideo><Heading>Project Heading</Heading><Description>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo nemo, molestiae quasi sapiente quam facere magni exercitationem enim et perferendis, saepe quibusdam voluptate esse dolores repellat minima, fugiat deserunt itaque fugit animi eum veniam! Cupiditate ut, quis, consectetur quos porro nam accusantium consequatur aliquid ratione, pariatur voluptate facere libero numquam!</Description><Overview>Learn More.,</Overview></ProjectDivision></Container></ProjectContainer>
    );
}